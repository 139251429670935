<template>
  <section class="product-section">
    <div
      v-if="MERCHANT_PRODUCT.merchant_name"
      class="product-section__breadcrumb"
    >
      <BreadCrumb
        class="top-left"
        :title="MERCHANT_PRODUCT.merchant_name"
      />
    </div>
    <div v-if="isLoading">
      <Loader color-class="l-grey" />
    </div>
    <div
      v-if="isProductNotActive && !isMerchantNotActive"
      class="product-section__product-not-active"
    >
      <img
        :src="ProductNotActive"
        alt=""
      > 
      <h1>Produk yang kamu cari tidak tersedia</h1>
      <p>Yuk cobain produk lainnya!</p>
      <Button
        text="Lihat Produk Lainnya"
        @click="redirectToMerchant"
      />
    </div>
    <div
      v-if="isMerchantNotActive"
      class="product-section__product-not-active"
    >
      <img
        :src="ProductNotActive"
        alt=""
      > 
      <h1>Toko Tidak Tersedia</h1>
      <p>Toko yang Anda cari tidak tersedia. Yuk, cek toko lainnya sekarang</p>
      <Button
        text="Lihat Toko Lainnya"
        @click="redirectToAllMerchant"
      />
    </div>
    <div
      v-if="!isLoading && !isProductNotActive"
      class="product-section__product-detail"
    >
      <div class="product-section__product-detail__content">
        <div class="product-section__product-detail__content__image">
          <!-- product image -->
          <!-- product image preview -->
          <div
            v-if="MERCHANT_PRODUCT.image_pdp"
            class="product-section__product-detail__content__image__preview"
          >
            <ImageLazy
              v-for="(imagePdp, i) of MERCHANT_PRODUCT.image_pdp"
              :key="i"
              :src="imagePdp"
              :alt="MERCHANT_PRODUCT.product_name + `_${i}`"
              @error="replaceDefaultImage"
              @touchstart="onTouchStart"
              @touchend="onTouchEnd"
            />
          </div>
          <div
            v-else
            class="skeleton-box"
            style="width: 500px; height:200px;"
          />
          <!-- product image preview -->

          <!-- product image list -->
          <div class="product-section__product-detail__content__image__list">
            <ImageLazy
              v-for="(imagePdp, i) of MERCHANT_PRODUCT.image_pdp"
              :key="i"
              :src="imagePdp"
              :alt="MERCHANT_PRODUCT.product_name + `_${i}`"
              class="pointer"
              :class="isSelectedPreviewImage(i)"
              @error="replaceDefaultImage"
              @click="() => changePreviewImage(i)"
            />
          </div>
          <div class="product-section__product-detail__content__image__indicator">
            <span 
              v-for="(_, i) of MERCHANT_PRODUCT.image_pdp"
              :key="i"
              :class="isSelectedPreviewImage(i)"
            />
          </div>
          <!-- product image list -->
          <!-- product image -->
        </div>
        <div class="product-section__product-detail__content__description">
          <!-- product description -->
          <h1
            v-if="isStockEmpty"
            class="product-section__product-detail__content__description__price-mobile"
            v-html="convertedRp"
          />
          <h1
            v-else
            class="product-section__product-detail__content__description__out-of-stock-mobile"
          >
            Produk Habis
          </h1>
          <h1
            class="product-section__product-detail__content__description__name"
            v-html="MERCHANT_PRODUCT.product_name"
          />
          <h1
            v-if="isStockEmpty"
            class="product-section__product-detail__content__description__price"
            v-html="convertedRp"
          />
          <h1
            v-else
            class="product-section__product-detail__content__description__out-of-stock"
          >
            Produk Habis
          </h1>
          <h5
            v-if="isStockEmpty"
            class="product-section__product-detail__content__description__stock"
          >
            Stok : 
            <span class="product-section__product-detail__content__description__stock__amount">
              {{ MERCHANT_PRODUCT.stock }}
            </span>
          </h5>
          <div class="product-section__product-detail__content__description__cta">
            <div class="btn">
              <Button
                text="Belanja Sekarang"
                @click="toggleDownloadModal"
              />
            </div>
            <div class="social">
              <SocialMediaShare :data="SocialMediaShareContentData" />
            </div>
          </div>
          <div class="product-section__product-detail__content__description__tab-description">
            <div class="product-section__product-detail__content__description__tab-description__tab">
              <h1
                class="product-section__product-detail__content__description__tab-description__tab__detail pointer"
                :class="activeTab === 'detail' ? 'active' : ''"
                @click="() => changeTabType('detail')"
              >
                Detail Produk
              </h1>
              <h1
                class="product-section__product-detail__content__description__tab-description__tab__information pointer"
                :class="activeTab === 'information' ? 'active' : ''"
                @click="() => changeTabType('information')"
              >
                Informasi Penting
              </h1>
            </div>
            <div class="product-section__product-detail__content__description__tab-description__content">
              <p v-html="renderedDescriptionText" />
              <span
                v-if="decideExpandDescription"
                class="pointer"
                @click="expandDescription"
              >{{ decideExpandDescriptionText }}</span>
            </div>
          </div>
          <div class="product-section__product-detail__content__description__merchant">
            <div class="product-section__product-detail__content__description__merchant__logo">
              <img 
                class="product-section__product-detail__content__description__merchant__logo__image"
                :src="MERCHANT_PRODUCT.brand_image"
                alt="merchant-logo"
              >
              <img
                class="product-section__product-detail__content__description__merchant__logo__check"
                src="/images/merchant/merchant-check.svg"
                alt="merchant-check"
              >
            </div>
            <div class="product-section__product-detail__content__description__merchant__info">
              <h1 class="product-section__product-detail__content__description__merchant__info__name">
                {{ MERCHANT_PRODUCT.merchant_name }}
              </h1>
              <p class="product-section__product-detail__content__description__merchant__info__location">
                {{ MERCHANT_PRODUCT.merchant_city }}
              </p>
            </div>
          </div>
          <div class="product-section__product-detail__content__description__social-media-share-mobile">
            <SocialMediaShare :data="SocialMediaShareContentData" />
          </div>
          <div class="product-section__product-detail__content__description__divider" />
          <div class="product-section__product-detail__content__description__description-mobile">
            <h1>Detail Produk</h1>
            <p v-html="MERCHANT_PRODUCT.description" />
          </div>
          <div class="product-section__product-detail__content__description__description-mobile">
            <h1>Informasi Penting</h1>
            <p v-html="MERCHANT_PRODUCT.information" />
          </div>
          <div class="product-section__product-detail__content__description__description-mobile">
            <h1>Stok</h1>
            <p v-html="MERCHANT_PRODUCT.stock" />
          </div>
          <!-- product description -->
        </div>
      </div>
    </div>
    <Modal
      v-if="isModalDownloadOpen"
      :header="true"
      :footer="true"
      width="500px"
      @close="toggleDownloadModal"
    >
      <template #header>
        <div class="download-modal__head">
          <h1 class="download-modal__head__title">
            Ayo, lanjutkan transaksi Anda di BisaBelanja & download aplikasinya sekarang!
          </h1>
          <p class="download-modal__head__subtitle">
            Nikmati kemudahan berbelanja, beragam toko & produk pilihan serta dapatkan diskon membership berkali-kali
          </p>
        </div>
      </template>
      <div class="download-modal__body">
        <h1 class="download-modal__body__title">
          Scan QR ini atau download aplikasi dari Playstore atau Appstore
        </h1>
        <img
          :src="decideQRCode"
          class="download-modal__body__qrcode"
          alt="qrcode_download"
        >
        <div class="download-modal__body__appstore-playstore">
          <img
            :src="`/images/playstore.webp`"
            class="pointer"
            alt="playstore"
            @click="redirectToPlaystore"
          >
          <img
            :src="`/images/appstore.webp`"
            class="pointer"
            alt="appstore"
            @click="redirectToAppStore"
          >
        </div>
      </div>
      <template #footer>
        <div class="download-modal__footer">
          <h1 class="download-modal__footer__title">
            Cara download aplikasi dengan Scan QR
          </h1>
          <div class="download-modal__footer__instruction">
            <div class="download-modal__footer__instruction__content">
              <img
                :src="`/images/merchant/download-instruction1.svg`"
                alt="open-scanner"
              >
              <div>
                <h1>
                  Buka kamera HP
                </h1>
                <p>
                  Aktifkan fitur Scan QR Code di pengaturan kamera
                </p>
              </div>
            </div>
            <div class="download-modal__footer__instruction__content">
              <img
                :src="`/images/merchant/download-instruction2.svg`"
                alt="open-scanner"
              >
              <div>
                <h1>
                  Notifkasi
                </h1>
                <p>
                  Klik notifikasi untuk langsung mendownload aplikasi
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <div
      v-if="!isLoading && !isProductNotActive"
      class="cta-mobile"
    >
      <div class="cta-mobile__content">
        <Button
          text="Belanja Sekarang"
          @click="toggleDownloadModal"
        />
      </div>
    </div>
  </section>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb.vue';
import Button from '@/components/new-button/Button.vue';
import MerchantUseCase from '../app/usecase/merchant';
import { convertRp } from '@/misc/currency';
import Loader from '@/components/new-loader/Loader.vue';
import SocialMediaShare from '@/components/social-media-share/SocialMediaShare.vue';
import Modal from '@/components/new-modal/Modal.vue';
import {
  getStoreUrlByOS,
  appStoreUrl,
  playStoreUrl,
  isAndroid,
  isIOS,
} from '../app/utils/getOs';
import ImageLazy from '@/components/ImageLazy/index.vue';
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import defaultSso from '@/helpers/SEODefault';
import { mapGetters } from 'vuex';

export default {
  name: 'MerchantProductDetail',
  components: {
    BreadCrumb,
    Button,
    Loader,
    SocialMediaShare,
    Modal,
    ImageLazy,
  },
  mixins: [mixinMetaInfo],
  metaInfo() {
    try {
      const formatCurrency = this.MERCHANT_PRODUCT.price
        ? this.MERCHANT_PRODUCT.price.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : 'IDR. 0';
      return this.SEO({
        title: `${this.MERCHANT_PRODUCT.product_name} - ${this.MERCHANT_PRODUCT.merchant_name} | BisaBelanja`,
        description: `Yuk! Cek produk dari ${this.MERCHANT_PRODUCT.merchant_name}: "${this.MERCHANT_PRODUCT.product_name}" dengan Harga ${formatCurrency} di BisaBelanja sekarang! Belanja Cermat, Ongkir Hemat.`,
        keywords: this.MERCHANT_PRODUCT.product_name,
        publishedTime: this.MERCHANT_PRODUCT.created_at,
        image: encodeURI(
          this.MERCHANT_PRODUCT.image_pdp
            ? this.MERCHANT_PRODUCT.image_pdp[0]
            : '',
        ),
        price: this.MERCHANT_PRODUCT.price,
        location: this.MERCHANT_PRODUCT.merchant_city,
      });
    } catch (error) {
      return this.SEO(defaultSso);
    }
  },
  data() {
    return {
      isLoading: false,
      isProductNotActive: false,
      isMerchantNotActive: false,
      productData: {},
      selectedImagePdpIdx: 0,
      isExpandDescription: false,
      productDescriptionText: '',
      activeTab: 'detail',
      intervalId: null,
      isModalDownloadOpen: false,
      isCopyLinkSuccess: false,
      SocialMediaShareContentData: {
        url: new URL(
          !this.isSsr()
            ? location.href
            : `http://${process.env.DEV_HOST}:${process.env.APP_PORT}`,
        ).href,
        title: '',
        description: '',
        quote: '',
        hashtags: '',
      },
      merchantUseCase: MerchantUseCase,
      convertRp,
      touchStartX: 0,
      touchEndX: 0,
    };
  },
  async asyncData({ route, store }) {
    try {
      const productSlugArray = route.params.product_slug.split('-');
      const product_id = productSlugArray[productSlugArray.length - 1];
      const res = await MerchantUseCase.getProductDetail(product_id);
      if (res.data) {
        store.dispatch('setMerchantProduct', res.data);
      }
    } catch (error) {
      store.dispatch('setMerchantProduct', {});
    }
  },
  computed: {
    ...mapGetters(['MERCHANT_PRODUCT']),
    renderedDescriptionText() {
      return this.sliceDescription(
        this.checkRenderedDescription(this.activeTab),
      );
    },
    decideExpandDescription() {
      return this.checkIsLongDescription(this.renderedDescriptionText);
    },
    decideExpandDescriptionText() {
      if (!this.isExpandDescription) {
        return 'Lihat Selengkapnya';
      }
      return 'Lihat Lebih Sedikit';
    },
    convertedRp() {
      return `Rp${this.convertCurr(this.MERCHANT_PRODUCT.price)}`;
    },
    isStockEmpty() {
      return !!this.MERCHANT_PRODUCT.stock;
    },
    decideQRCode() {
      return '/images/merchant/prod-qrcode.png';
    },
  },
  mounted() {
    this.getProductIdFromSlug();
    this.getProductDetail();
  },
  methods: {
    sliceDescription(desc) {
      if (desc) {
        if (desc.length >= 200 && !this.isExpandDescription) {
          return `${desc.substring(0, 200)}...`;
        }
        return desc;
      }
    },
    expandDescription() {
      this.isExpandDescription = !this.isExpandDescription;
    },
    getProductIdFromSlug() {
      const productSlugArray = this.$route.params.product_slug.split('-');
      const merchabtSlugArray = this.$route.params.merchant_slug.split('-');
      this.productData.product_id =
        productSlugArray[productSlugArray.length - 1];
      this.productData.merchant_id =
        merchabtSlugArray[merchabtSlugArray.length - 1];
    },
    async getProductDetail() {
      try {
        this.isLoading = true;
        const res = await this.merchantUseCase.getProductDetail(
          this.productData.product_id,
        );
        if (res.data) {
          this.$store.dispatch('setMerchantProduct', res.data);
          const copyUrl = this.SocialMediaShareContentData.url;
          this.SocialMediaShareContentData = {
            ...this.SocialMediaShareContentData,
            title: `Yuk! Cek produk dari ${this.productData.merchant_name}: "${
              this.productData.product_name
            }" dengan Harga Rp ${this.convertCurr(
              this.productData.price,
            )} di BisaBelanja sekarang! Belanja Cermat, Ongkir Hemat `,
            hashtags: 'lionparcel',
            copyUrl,
          };
        }
        if (res.err) {
          this.isProductNotActive = true;
          // get merchant name when product is not active
          const merchantRes = await this.merchantUseCase.getMerchant(
            this.productData.merchant_id,
          );
          if (merchantRes.err) {
            this.isMerchantNotActive = true;
          }
          if (merchantRes.data) {
            this.productData.merchant_name = merchantRes.data.merchant_name;
          }
        }

      } catch (error) {
        this.$store.dispatch('setMerchantProduct', {});
      } finally {
        this.isLoading = false;
      }
    },
    convertCurr(value) {
      if (value) {
        return this.convertRp(value);
      }
    },
    changePreviewImage(idx) {
      this.slidePDPImage(idx);
      this.selectedImagePdpIdx = idx;
    },
    isSelectedPreviewImage(idx) {
      return this.selectedImagePdpIdx === idx ? 'active' : '';
    },
    checkRenderedDescription(type) {
      if (type === 'detail') {
        return this.MERCHANT_PRODUCT.description;
      }
      return this.MERCHANT_PRODUCT.information;
    },
    changeTabType(type) {
      this.activeTab = type;
    },
    checkIsLongDescription(desc) {
      return desc && desc.length >= 200;
    },
    onTouchStart(e) {
      this.touchStartX = e.changedTouches[0].screenX;
    },
    onTouchEnd(e) {
      this.touchEndX = e.changedTouches[0].screenX;
      this.checkDirection();
    },
    checkDirection() {
      if (this.touchEndX < this.touchStartX) {
        if (
          this.selectedImagePdpIdx !==
          this.MERCHANT_PRODUCT?.image_pdp?.length - 1
        ) {
          this.selectedImagePdpIdx++;
          this.slidePDPImage(this.selectedImagePdpIdx);
        }
      }
      if (this.touchEndX > this.touchStartX) {
        if (this.selectedImagePdpIdx) {
          this.selectedImagePdpIdx--;
          this.slidePDPImage(this.selectedImagePdpIdx);
        }
      }
    },
    slidePDPImage(idx) {
      document.querySelector(
        '.product-section__product-detail__content__image__preview',
      ).style.transform = `translateX(${-idx * 300}px)`;
    },
    redirectToApp() {
      const intervalId = window.setTimeout(() => {
        const storeUrl = getStoreUrlByOS();
        window.location = storeUrl;
      }, 500);
      window.location = `lionparcel://app/shop/product/${this.MERCHANT_PRODUCT.product_id}`;
      window.addEventListener('blur', () => {
        clearTimeout(intervalId);
      });
      document.addEventListener(
        'visibilitychange',
        () => {
          if (document.hidden) {
            clearTimeout(intervalId);
          }
        },
        false,
      );
    },
    toggleDownloadModal() {
      if (this.isModalDownloadOpen === false && (isAndroid() || isIOS())) {
        return this.redirectToApp();
      }
      setTimeout(() => {
        this.isModalDownloadOpen = !this.isModalDownloadOpen;
      }, 50);
      window.scrollTo({ left: 0, top: 10, behavior: 'smooth' });
    },
    redirectToPlaystore() {
      window.open(playStoreUrl, '_blank');
    },
    redirectToAppStore() {
      window.open(appStoreUrl, '_blank');
    },
    redirectToMerchant() {
      this.$router.push(
        `/merchant/detail/${encodeURIComponent(
          this.$route.params.merchant_slug,
        )}`,
      );
    },
    redirectToAllMerchant() {
      this.$router.push('/merchant/all');
    },
    replaceDefaultImage(e) {
      e.target.src = '/images/merchant/default-product-image.svg';
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
@media screen and (min-width: 0px) and (max-width: 769px) {
  body {
    padding-bottom: 72px !important;
  }
}
</style>
